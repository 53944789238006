.swat-frame {
  border: 0;

  .swat-frame-title,
  .swat-frame-contents,
  .swat-actions,
  .swat-header-form-field,
  .swat-footer-form-field {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
  }

  .swat-frame-title {
    padding: 1rem;
    color: map-get($colors, primary);
    background-color: map-get($colors, gray-100);
  }

  .swat-frame-contents {

    .swat-frame-contents {

      > .swat-table-view {
        margin-top: 0;
      }
    }

    .delta-report-index {
      margin-top: 0;
    }
  }

  .swat-none {
    font-size: 123.1%;
    padding: 2em 0;
    text-align: center;
  }
}
