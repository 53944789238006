#top-nav-container {
  padding: 0 $grid-gutter-width;
  text-align: right;
  background-color: map-get($colors, gray-100);

  ul {

    li {
      display: inline-block;
      list-style-type: none;
      line-height: 1.9;
      font-size: 77%;
      border-right: 1px solid map-get($colors, gray-300);

      &:last-child {
        border-right: none;
      }
    }
  }

  #top-nav {

    li {
      padding-right: .5em;
      margin-right: .5em;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
      }
    }

    i {
      position: relative;
      top: .1em;
      font-size: 1.2em;
    }
  }
}
