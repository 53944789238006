.swat-toolbar,
ul.swat-toolbar {
  top: 0;
  margin: 0 0 1rem 0;
  padding: 1rem 0;
  border-bottom: 0;
  background: none;
  background-color: map-get($colors, gray-200);

  li {
    display: inline-block;
    margin-bottom: .25em;

    &:last-child {

      a {
        border: 0;
      }
    }
  }

  .swat-tool-link {
    display: block;
    padding: .25em 1rem;
    font-size: .9em;
    border-color: map-get($colors, gray-500);
    border-bottom: 0;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      background: none;
    }
  }

  .swat-tool-link-edit,
  .swat-tool-link-edit:link,
  .swat-tool-link-edit:visited,
  .delta-report-tool-link-export,
  .delta-report-tool-link-export:link,
  .delta-report-tool-link-export:visited {
    position: relative;
    background: none;
    padding-left: 2.5rem;

    &::before {
      display: inline-block;
      font: normal normal normal 14px/1 "LineAwesome";
      font-size: inherit;
      text-rendering: auto;
      text-decoration: inherit;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      position: absolute;
      left: 1rem;
      top: 0;
      font-size: 1.5em;
      text-decoration: none;
    }
  }

  .swat-tool-link-edit,
  .swat-tool-link-edit:link,
  .swat-tool-link-edit:visited {

    &::before {
      content: '\f1c1';
    }
  }

  .delta-report-tool-link-export,
  .delta-report-tool-link-export:link,
  .delta-report-tool-link-export:visited {

    &::before {
      content: '\f1e3';
    }
  }
}
