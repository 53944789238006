/* Grid */

$grid-gutter-width: 15px;

/* Colors */

$colors: (
  primary:          #ce5c00,
  black:            #000,
  white:            #fff,
  light-red:        #f2dbdb,
  red:              #f00,
  red-400:          #a00,
  green:            #6eb220,
  light-green:      #eaf1dd,
  blue:             #0055ac,
  light-blue:       #c6d9f1,
  blue-100:         #edf5ff,
  gray-50:          #fafafa,
  gray-100:         #f5f5f5,
  gray-150:         #f0f0f0,
  gray-200:         #e0e0e0,
  gray-300:         #ccc,
  gray-500:         #aaa,
  gray-700:         #7f7f7f,
  brown:            #7b4b09,
  yellow-200:       #fffbc9,
  purple:           #6b006c,
  fb-blue:          #3b5998,
  fb-light-blue:    #8b9dc3,
  fb-lightest-blue: #dfe3ee
);

/* Fonts */

$fonts: (
  body:   ("Montserrat", Georgia, serif),
  header: ("Poppins", Helvetica, Arial, sans-serif)
);
