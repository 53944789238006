body[class^="trc-login"] {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;

  #doc3 {
    width: 60vw;
    margin: $grid-gutter-width auto;
  }

  p {
    margin: 1em 0;
  }

  .trc-login-body {

    .login-header {
      color: map-get($colors, white);
      background-color: map-get($colors, primary);
    }

    .swat-frame {
      margin: 0;
    }
  }

  #delta_login_form {
    padding-top: 1rem;

    input[type="text"],
    input[type="password"] {
    }

    label[for="remember"] {
      margin-right: 0;
      font-weight: 300;
      font-size: 80%;
      vertical-align: middle;
    }

    #remember {
      height: 1rem;
      width: 1rem;
      font-size: 70%;
    }

    .swat-footer-form-field {
      margin: 0;
      padding: 0;
      background: none;
    }

    #submit {
      margin: 1rem 0 0 0;
    }

    #submit_cancel {
      display: none;
    }
  }

  #auth_form {

    #submit_cancel {
      font-size: smaller;
    }
  }

  .trc-campaign-information {

    .trc-campaign-slogan {
      margin: .5rem 0;
      font-style: italic;
      color: map-get($colors, gray-500);
    }

    .trc-campaign-title {
      color: map-get($colors, primary);
      font-size: 1em;
    }

    .trc-label {
      color: map-get($colors, gray-500);
      text-transform: uppercase;
    }

    .trc-value {
      color: map-get($colors, primary);
      font-weight: 400;
      font-size: 1.5em;
      letter-spacing: -0.5px;
    }

    #campaign_progress_text {
      font-size: .9em;
      color: map-get($colors, gray-700);
    }
  }

  #trc-campaign-icon {
    display: block;
    width: 90%;
    margin: 2rem auto;
  }

  .trc-help,
  .trc-login-footer {
    text-align: center;
  }

  .trc-login-footer {
    margin-top: 1rem;
  }

  .brand {
    display: inline-block;
    width: 12rem;
    margin-right: .5rem;
    vertical-align: middle;
    border: 0;
  }
}
