form {
  $input-bg-color:     map-get($colors, gray-50);
  $input-border-color: map-get($colors, gray-500);

  input,
  select,
  textarea {
    font-family: map-get($fonts, body);
    font-weight: 300;
    padding: .5em;
    border: 0;
    border: 1px solid $input-border-color;
    background-color: $input-bg-color;
    transition: border-color .15s ease-in;

    &:focus {
      outline: none;
      border: 1px solid map-get($colors, primary);
    }

    &:disabled {
      border: 1px solid map-get($colors, gray-300) !important;
      background-color: map-get($colors, white) !important;
    }
  }

  input,
  select {
    margin-right: .5em;
  }

  input[type="text"],
  input[type="search"],
  input[type="password"] {
    height: 1em;
  }

  input[type="submit"] {
    @extend .btn;
  }

  label {
    margin-right: .5em;
  }

  select {
    border-radius: 2px;
  }

  textarea {
  }

  input[type="checkbox"] {
    position: relative;
    height: 1.5em;
    width: 1.5em;
    border: 1px solid $input-border-color;
    border-radius: 2px;
    background-color: $input-bg-color;
    // @todo: Edge doesn't render this properly (?)
    // -webkit-appearance: none;
    //         appearance: none;
    //
    // &::after {
    //   content: '\f17b';
    //   font: normal normal normal 16px/1 "LineAwesome";
    //   font-size: inherit;
    //   text-decoration: inherit;
    //   text-rendering: optimizeLegibility;
    //   text-transform: none;
    //   -moz-osx-font-smoothing: grayscale;
    //   -webkit-font-smoothing: antialiased;
    //   font-smoothing: antialiased;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   opacity: 0;
    //   visibility: hidden;
    //   color: map-get($colors, primary);
    //   transition: opacity .1s ease-in;
    // }

    &:focus {
      border: 1px solid map-get($colors, primary);
    }

    &:checked {

      // &::after {
      //   transform: translate(-50%, -50%);
      //   opacity: 1;
      //   visibility: visible;
      // }
    }
  }

  /* Swat specific */

  &.swat-form {
    margin: 0;
    padding: 0;
    border: none;
    background: none;

    .swat-frame {
      border: 0;
      background: none;

      .swat-header-form-field {
        margin: 0 0 -1rem 0;
        padding: .5rem;
        font-size: .85em;
        text-align: right;
        background-color: map-get($colors, gray-100);

        input[type="submit"] {
          font-weight: 300;
        }
      }

      .swat-footer-form-field {
        margin: 0 0 -1rem 0;
        padding: 1rem;
        background-color: map-get($colors, gray-100);
      }

      .swat-frame-title {
        position: relative;
        padding: .5rem;
        color: #fff;
        font-size: 123.1%;
        font-weight: normal;
        border: 1px solid map-get($colors, primary);
        background-color: map-get($colors, primary);
        background-image: none;
      }
    }

    label {

      .swat-required {
        font-size: 70%;
        color: map-get($colors, red-400);
      }
    }
  }
}

#delta_admin_listing_form {

  .swat-header-form-field {
    text-align: left;
  }
}
