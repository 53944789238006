.swat-calendar {

  .swat-calendar-toggle-button {
    padding: .25em;
    border: 0;
    background: none;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      border: 0;
    }

    &::before {
      content: '\f15c';
      display: inline-block;
      font: normal normal normal 14px/1 "LineAwesome";
      font-size: inherit;
      text-rendering: auto;
      text-decoration: inherit;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      position: relative;
      top: .25em;
      font-size: 1.7em;
      color: map-get($colors, primary);
      text-decoration: none;
    }
  }
}
