.yui-skin-sam .yui-accordionview,
.yui-accordionview,
#trc-accordion-view.yui-accordionview {
  width: 100% !important;

  .yui-accordion-panel {
    width: 100%;

    a.yui-accordion-toggle {
      background: none;

      &:hover,
      &:active,
      &:focus,
      &:active:focus {
        color: map-get($colors, white);
        text-decoration: none;
        background-color: map-get($colors, primary);

        .indicator {

          &::before {
            color: map-get($colors, white);
          }
        }
      }

      &.active {
        color: map-get($colors, white);
        border-color: map-get($colors, primary);
        background-color: map-get($colors, primary);
      }
    }
  }

  .yui-accordion-content {
    padding: $grid-gutter-width;
  }

  .yui-accordion-toggle {

    .indicator {
      top: 50%;
      transform: translateY(-50%);
      height: auto;
      width: auto;
      background: none;

      &::before {
        content: '\f2c3';
        display: inline-block;
        font: normal normal normal 14px/1 "LineAwesome";
        font-size: inherit;
        text-rendering: auto;
        text-decoration: inherit;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-smoothing: antialiased;
        font-size: 1.5em;
        color: map-get($colors, primary);
        text-decoration: none;
      }
    }

    &.active {

      .indicator {

        &::before {
          content: '\f28f';
          color: map-get($colors, white);
        }
      }
    }
  }
}
