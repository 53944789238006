.side-module {
  margin-bottom: $grid-gutter-width;

  .side-module-header {
    padding: 1rem;
    background-color: map-get($colors, white);

    i {
      position: relative;
      top: -.05em;
      margin-right: .2em;
      font-size: 2.5em;
      vertical-align: middle;
      color: map-get($colors, gray-700);
    }

    & ~ .side-module-content {
      padding-top: 0;
    }
  }

  .side-module-content {
    padding: 1rem;
    background-color: map-get($colors, white);

    &.module-center > * {
      width: 40%;
      margin: 0 auto;
    }

    li {
      margin-bottom: 1rem;
    }
  }

  .side-module-footer {
    font-size: 77%;
    padding: .25rem .5rem;
    text-align: right;
    border: 2px solid map-get($colors, white);
    background-color: map-get($colors, gray-100);
  }

  /* Module specific */

  &#sales-tips {

    a {
      font-size: 1.25em;
    }
  }

  &#campaign-icon {

    .trc-campaign-icon {
      width: 100%;
    }
  }

  &#leaderboard {

    #campaign_leaderboard_flydown {
      width: 68%;
      margin-right: 3%;
    }

    #trc_campaign_leaderboard_button {
      width: 28%;
      margin-right: 0;
    }
  }
}
