#trc_sales_search_form-container {
  margin-bottom: 2rem;
  border: 1px solid map-get($colors, gray-200);
  background-color: map-get($colors, white);

  .trc-search-field-container	{
    padding: 1rem;
    background-color: map-get($colors, gray-200);
    overflow: hidden;
  }

  .trc-advanced-search-container,
  .swat-footer-form-field {
    padding: 1rem 1rem 0;
  }

  .swat-footer-form-field {
    margin-bottom: 1rem;
    padding-top: 0;
  }

  #keywords-field,
  #Product-Id-Field {
    float: left;
    width: 40%;
    margin: 0;

    .swat-form-field-contents {
      width: 100%;
    }
  }

  #trc-search-date-container {
    width:42%;
    float:left;
    margin-left:1%;
  }

  #trc-search-price-container {
    width:47%;
    float:left;
  }

  #trc-search-options-container {
    clear:both;
    padding:1%;
    width:98%;
  }

  #trc-search-options-container {

    li {
        margin-right:40px;
    }

    #SwatCheckAll1 {
        margin-top:25px;
    }
  }
}

#user_filter_container {
  margin: 0;
  padding: 0;

  span.swat-required {
    display: none;
  }

  div {
    display: inline-block;
  }
}
