#trc-sales-event-details {

  ul.trc-sales-details {
    margin-top: .5rem;

    li {
      float: left;
      display: block;
      width: 50%;
      margin: .5rem 0;
      box-sizing: border-box;

      i {
        float: left;
        margin-right: .2em;
        font-size: 2em;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        margin-top: .15em;
      }

      &.trc-available-products-link {
        font-size: .9em;
      }
    }
  }
}

#trc-accordion-view {

  .trc-level-information {

    .trc-level-benefits {
      margin-bottom: 1rem;

      i {
        position: relative;
        top: -.1em;
        margin-right: .25rem;
        font-size: 2em;
        vertical-align: middle;
        opacity: .5;
      }
    }
  }
}


#trc-sales-event-info {

  h2 {
    color: map-get($colors, black);
    font-size:200%;
    font-weight:bold;
    margin-bottom: .5em;
  }

  p {
    font-size:120%;
  }
}

#trc-sales-campaign-info {
  display: none;
}
