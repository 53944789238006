#progress {
  $height:        2rem;
  $padding-left:  3.337%;
  $border-radius: .5em;
  $border:        1px solid map-get($colors, gray-700);

  margin: 0 0 2rem 0;

  ul {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }

  li {
    display: inline-block;
    position: relative;
    line-height: $height;
    width: 30%;
    padding-left: $padding-left;
    text-align: center;
    font-size: .9em;
    color: map-get($colors, gray-700);
    border-top: $border;
    border-bottom: $border;
    opacity: .75;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      right: -($height / 2);
      width: 0;
      height: 0;
      border-top: ($height / 2) solid transparent;
      border-bottom: ($height / 2) solid transparent;
      border-left: ($height / 2) solid map-get($colors, white);
    }

    &::after {
      z-index: 1;
      right: -(($height / 2) + .075);
      border-left: ($height / 2) solid map-get($colors, gray-700);
    }

    &:first-child {
      padding-left: 0;
      border-radius: $border-radius 0 0 $border-radius;
      border-left: $border;
    }

    &:last-child {
      border-radius: 0 $border-radius $border-radius 0;
      border-right: $border;

      &::before,
      &::after {
        display: none;
      }
    }

    &.active,
    &.next_step {
      color: map-get($colors, primary);
      border-color: map-get($colors, primary);
      opacity: 1;

      &::after {
        border-left-color: map-get($colors, primary);
      }
    }
  }

  /* Steps */

  #choose_product {

    &.next_step {
    }

    &.active {
    }
  }

  #sign_contract {

    &.next_step {
    }

    &.active {
    }
  }

  #review {

    &.next_step {
    }

    &.active {
    }
  }
}
