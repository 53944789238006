.swat-message-notification,
.swat-message-display .swat-message,
.swat-message-display .swat-message.swat-message-notification {
  $padding: 1rem;
  position: relative;
  min-height: 1.75rem;
  padding: $padding $padding $padding 4rem;
  border: 0;
  border-radius: .5rem;
  background: none;
  background-color: map-get($colors, yellow-200);

  &::before {
    content: "\f266";
    display: inline-block;
    font: normal normal normal 14px/1 "LineAwesome";
    font-size: inherit;
    text-rendering: auto;
    text-decoration: inherit;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    position: absolute;
    left: $padding;
    top: $padding;
    transform: translateY(-.05em);
    font-size: 2.5em;
    color: map-get($colors, brown);
    opacity: .5;
  }

  .swat-message-container {
    min-height: 0;
    padding: 0;
  }

  .swat-message-primary-content,
  .swat-message-secondary-content {
    padding-right: 2rem;
  }

  .swat-message-primary-content {
    line-height: 1.8;
    color: map-get($colors, brown);
  }

  .swat-message-display-dismiss-link {
    $dismiss-size: 1.25em;
    display: inline-block;
    font: normal normal normal 14px/1 "LineAwesome";
    font-size: inherit;
    text-rendering: auto;
    text-decoration: inherit;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    float: none;
    position: absolute;
    top: $padding;
    right: $padding;
    transform: translateY(-.1em);
    height: $dismiss-size;
    width: $dismiss-size;
    margin: 0;
    border: 0;
    background: none;
    text-indent: 0;
    color: map-get($colors, gray-500);
    overflow: hidden;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      color: map-get($colors, red);
    }

    &::before {
      content: "\f191";
      font-size: $dismiss-size;
    }
  }
}

#trc_facebook_message {
  margin-top: 0;

  .swat-message-notification {
    margin: 0 0 1rem 0;
    background-color: map-get($colors, fb-lightest-blue);

    &::before {
      content: "\f1d7";
      color: map-get($colors, fb-blue);
    }

    .swat-message-primary-content {
      color: map-get($colors, fb-blue);
    }

    .swat-message-container {
      padding: 0;
    }

    .swat-message-secondary-content {
      color: map-get($colors, black);
    }

    p {
        margin: 1em 0;
    }

    img {
      margin-right: 6px;
      vertical-align: middle;
    }
  }
}
