.nav {

  ul {

    li {
      display: inline;
    }
  }

  &.tabs {

    ul {
      display: block;
      margin-left: 15px;
    }

    li {

      &.trc-active a {
        color: map-get($colors, white);
        border: 0;
        background-color: map-get($colors, primary);
      }

      a {
        display: inline-block;
        margin: 0 0.5% 0 0;
        padding: .5em 4%;
        text-align: center;
        font-size: 105%;
        letter-spacing: -0.25px;
        color: map-get($colors, primary);
        text-decoration: none;
        border: 0;
        border-radius: .5rem .5rem 0 0;
        background-color: #F0F0F0;
        transition: color .15s ease-in,
                    background-color .15s ease-in;

        &:hover,
        &:active,
        &:focus,
        &:active:focus {
          border: none;
          background-color: map-get($colors, primary);
          color: map-get($colors, white);
        }
      }
    }
  }
}
