/* Contact Page */
div#trc-contracts-page {
    border:#e7e7e7 solid 1px;
    width:96%;
    padding:2%;
}

div#trc-contracts-page div.trc-contracts-left {
    width:45%;
    float:left;
}

div#trc-contracts-page div.trc-contracts-right {
    width:45%;
    float:right;
}

div#trc-contracts-page ul li ul {
    margin-left:20px;
    margin-top:15px;
}

div#trc-contracts-page ul.bullets li ul li {
    list-style-type: disc;
    list-style-position:outside;
    line-height:1.4em;
}

div#trc-contracts-page div#sold_to {
    background-color:#f3f3f3;
    margin-bottom:20px;
}

div#trc-contracts-page div#sold_to fieldset,
div#trc-contracts-page div#additional_information fieldset {
    margin:0;
    background-color:#f3f3f3;
}
div#trc-contracts-page div#sold_by {
    padding:10px 0 5px 13px;
    background-color:#f3f3f3;
}
div#trc-contracts-page div#sold_by strong {
    color:#666666;
    font-weight:bold;
    line-height:2;
    padding:0 0.3em;
}

div#trc-contracts-page div#additional_information,
div#trc-contracts-page div#additional_information div.swat-displayable-container {
    background-color:#f3f3f3;
    margin-bottom:15px;
}

div#trc-contracts-page div#additional_information div.swat-form-field-checkbox {
    width:20%;
    background:#FFFFFF;
    padding:1%;
    border: 1px solid map-get($colors, primary);
}

div#trc-contracts-page div.yui-content {
    background:#FFFFFF;
}

div#trc-contracts-page table#sold_by_details {
    width:50%;
}
div#trc-contracts-page table#sold_by_details th {
    padding-right:10px;
    color:map-get($colors, black)000;
}
