#campaign_progress_bar {
  $size: 2rem;
  margin: .5rem 0;
  border: 1px solid map-get($colors, gray-200);
  background-image: none;
  background-color: map-get($colors, blue-100);

  &,
  & div {
    height: $size;
    border-radius: $size;
  }

  #campaign_progress_full {
    background-image: none;
    background-color: map-get($colors, blue);
  }
}
