.btn,
.button,
.trc-button,
button {
  display: inline-block;
  background-color: map-get($colors, green);
  color: map-get($colors, white);
  font-family: map-get($fonts, body);
  font-weight: 300;
  padding: .5em 1em;
  border-radius: 0.1429rem;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.07), 0 3px 1px -2px rgba(0,0,0,0.06), 0 1px 5px 0 rgba(0,0,0,0.1);

  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    color: map-get($colors, white);
    border: 0;
    background-color: darken(map-get($colors, green), 10%);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.07), 0 1px 7px 0 rgba(0,0,0,0.06), 0 3px 1px -1px rgba(0,0,0,0.1);
    transition: background-color .15s ease-in,
                box-shadow .15s ease-in;
  }

  &.btn-primary {
    background-color: map-get($colors, primary);

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      background-color: darken(map-get($colors, primary), 10%);
    }
  }

  > i:first-child {
    margin-right: .25em;
  }
}

/* Sell buttons */

.trc-button {
  margin: 0 .5rem 1rem 0;
  padding: 0;
  cursor: pointer;

  > a,
  > span {
    display: inline-block;
    padding: .5em 1em;
    color: map-get($colors, white);
    border: 0;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      border: 0;
    }
  }

  &.trc-inactive {
    color: map-get($colors, gray-700);
    background: map-get($colors, gray-500);
  }

  &.trade {
    background: map-get($colors, primary);

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      background-color: darken(map-get($colors, primary), 10%);
    }
  }

  &.trc-button-sold-out,
  .trc-search-result-sold-out {
    background: map-get($colors, red);
    cursor: default;
    box-shadow: none;
  }
}

.btn-facebook {
  position: relative;
  font-weight: bold;
  border-radius: 5rem;
  border: 1px solid #aaa;
  font-size: 11px;
  line-height: 3em;
  padding: .5em 1em;
  white-space: nowrap;
  background: #ddd;
  color: #333;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    color: map-get($colors, white);
    border-color: map-get($colors, fb-blue);
    background-color: map-get($colors, fb-blue);

    i {
      color: map-get($colors, white);
    }
  }

  i {
    position: relative;
    top: .2em;
    color: map-get($colors, fb-blue);
    font-size: 2em;
    transition: color .15s ease-in;
  }
}
