.swat-frame-disclosure {
  margin: .5rem $grid-gutter-width;
}

.swat-disclosure {

  .swat-frame-title [class^="swat-disclosure-anchor"] {
    display: block;
    padding: 0 0 0 2rem;
    color: map-get($colors, white);

    &::before {
      top: 0;
      color: map-get($colors, white);
      opacity: .5;
    }

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      color: map-get($colors, white);

      &::before {
        color: map-get($colors, white);
        opacity: 1;
      }
    }
  }

  [class^="swat-disclosure-anchor"] {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
    background-image: none;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      border-bottom: 0;

      &::before {
        color: map-get($colors, black);
      }
    }

    &::before {
      display: inline-block;
      font: normal normal normal 14px/1 "LineAwesome";
      font-size: inherit;
      text-rendering: auto;
      text-decoration: inherit;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      position: absolute;
      top: -0.025em;
      left: 0;
      font-size: 1.4em;
      color: map-get($colors, primary);
      text-decoration: none;
    }
  }

  .swat-disclosure-anchor-closed {

    &::before {
      content: '\f16a';
    }
  }

  .swat-disclosure-anchor-opened {

    &::before {
      content: '\f168';
    }
  }
}
