#campaign_progress {

  #campaign_progress_text {
    display: inline-block;
  }
}

#trc-campaign-manual-sections a {
    padding: 0 0 0 4px;
}

.campaign-manual-sponsor {
    font-size: 0.9em;
    text-align: right;
}
