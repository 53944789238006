#ft {
  margin: 1rem $grid-gutter-width;
  font-size: 77%;

  #ft-logo,
  #copyright {
    float: left;
    width: 50%;
  }

  #ft-logo {

    .brand {
      display: inline-block;
      width: 10rem;
      border: 0;
    }
  }

  #copyright {
    text-align: right;
    line-height: 2em;
  }

  #debugging {
    float:left;
    width:40%;
    text-align:left;
  }
}
