table {

  &.swat-table-view {
    width: 100%;
    border: 0;
    margin-top: 1rem;
    border-bottom: 1px solid map-get($colors, gray-200);

    th {
      color: map-get($colors, black);
      padding: .2rem .5rem;
      text-align: center;
      white-space: normal;
      background: map-get($colors, gray-200);
      border-bottom: 1px solid map-get($colors, gray-700);
    }

    tr {
      background-image: none;

      &.odd {
        background-color: map-get($colors, blue-100);
      }

      &.trc-search-result-sold-out td swat-numeric-cell-renderer {
        color: map-get($colors, red);
      }

      &.trc-incentive-payment-received {
        color:#000066;
        background-color: map-get($colors, light-blue);
      }

      &.trc-incentive-payment-pending {
        color:#006600;
        background-color: map-get($colors,light-green);
      }

      &.trc-incentive-payment-almost {
        color: #984806;
        background-color: map-get($colors, light-red);
      }

    }

    td {
      padding: .5rem;
      text-align: left;
      border-right: #cbcbcb solid 1px;

      &.item-sold {
        font-size: .85em;
      }

      &:last-child {
        border-right: 0;
      }

      &.swat-image-link-cell-renderer {
        width: auto;

        img {
          padding: 0 3px 3px 0;
          vertical-align: middle;
        }
      }

      &.trc-user-cell-renderer {
        white-space: nowrap;

        a {
          border-bottom: none;
          text-decoration: none;
        }

        img,
        span {
          vertical-align: middle;
        }

        .trc-profile-pic {
          margin-right: 6px;
        }

        .trc-user-role {
          margin-left: 6px;
        }
      }
    }
  }
}
