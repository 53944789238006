#hd {
  margin: 0 auto;
  padding: 1rem $grid-gutter-width 0 $grid-gutter-width;
  background: map-get($colors, white);
}

#hd-content {
  margin: 0 auto;

  .trc-campaign-icon,
  .trc-campaign-header {
    display: inline-block;
  }

  .trc-campaign-icon {
    position: relative;
    top: .35em;
    float: left;
    margin-right: .5rem;
  }

  h1 {
    font-size: 150%;
    font-weight: bold;
  }

  h2 {
    color: map-get($colors, primary);
    font-size: 108%;
    font-weight: bold;
  }
}

#header-title {
}
