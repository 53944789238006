#sales_display {
  margin-top: 0;

  > .swat-frame-contents {
    padding-top: 0;
  }

  .swat-header-form-field #submit {
    margin-right: 0;
  }
}

.home-module {
  float: left;
	width: 45%;
  padding: 2%;
  background: repeating-linear-gradient(
    -45deg,
    map-get($colors, gray-150),
    map-get($colors, gray-150) 1px,
    transparent 1px,
    transparent 5px
  );

  &:first-child {
    margin-right: 2%;
  }

  header {
    margin-bottom: 1rem;

    i {
      font-size: 2em;
      vertical-align: middle;
      color: map-get($colors, gray-700);
    }

    a {
      font-size: 80%;
    }
  }
}

#trc-campaign-summary {

  p {
    margin: 1em 0;
  }

  .btn {
    margin-top: 1rem;
  }
}
