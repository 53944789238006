.trc-user-frame {

  .swat-details-view th {
    white-space: nowrap;
    width: 240px;
  }

  .swat-details-view {
    margin-bottom: 12px;
  }
}
