#my_sales_summary {

  .swat-frame-contents {
    padding: 1rem;
  }
}

#my_sales_display {

  input[type="submit"] {
    margin-right: 0;
  }
}

#my_contract_summary {

  table.contract-header {
    width: 100%;

    td[class^="contract-header"] {
      width: 50%;
      vertical-align: top;
    }
  }

  #default-campaign-icon {
    display: inline-block;
    margin-right: .5rem;
    vertical-align: top;
  }

  .contract-campaign-header {
    display: inline-block;

    h2 {
      color: map-get($colors, primary);
    }
  }

  .contract-campaign-address {
    margin-top: .5rem;
  }

  table.swat-table-view {
    margin-bottom: 2rem;
    vertical-align: top;

    td, th {
      width: auto;
      vertical-align: top;
    }
  }

  .summary {
    overflow: hidden;

    #default-campaign-icon {
      float: left;
      width: 20%;
      margin-right: 1rem;
      vertical-align: middle;
    }
  }
}

#contract-header-row2 {
  margin-top: 1rem;
}

#contract-section1 {
  margin-bottom: 2rem;
  page-break-after: always;
}

#contract-section1-printfooter,
#contract-section2-printheader {
  display: none;
}

#contract-shell {

  fieldset {
    position: relative;
    margin-top: 1rem;
    padding: 0 1rem 1rem 1rem;
    border-width: 1px;
    border-color: #ccc;
    border-style: dotted;

    &.swat-fieldset {
      border-style: solid;
    }

    legend {
      font-weight: bold;
      color: #666;
      padding: 0 0.3em;
      line-height: 2;
    }
  }
}

#print-content #my_contract_summary {
  margin: 0;
  padding: 1rem;

  .swat-toolbar {
    display: none;
  }

  #default-campaign-icon {
    width: 30%;
  }

  .contract-campaign-header {
    width: 65%;

    h1 {
      font-size: 150%;
      line-height: 1.2;
    }
  }

  #contract-section1-printfooter {
    margin-top: 2rem;
    border-top: 1px dashed #000;
  }
}
