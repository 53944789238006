@import "common/variables";
@import "common/global";

@import "components/accordions";
@import "components/buttons";
@import "components/campaign-progress-bar";
@import "components/forms";
@import "components/navs";
@import "components/notifications";
@import "components/pagination";
@import "components/sales-progress-bar";
@import "components/swat-calendar";
@import "components/swat-disclosure";
@import "components/swat-frame";
@import "components/swat-toolbar";
@import "components/tables";

@import "layouts/campaigns";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/side-module";
@import "layouts/sidebar";

@import "layouts/navs/top-nav";
@import "layouts/navs/main-nav";

@import "layouts/pages/contracts";
@import "layouts/pages/home";
@import "layouts/pages/login";
@import "layouts/pages/my-sales";
@import "layouts/pages/sales-event";
@import "layouts/pages/sales-find";
@import "layouts/pages/user";
