html {
  background-color: map-get($colors, gray-150);
}

body {
  text-align: left;
  font-family: map-get($fonts, body);
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
}

a {
  outline: none;
  color: map-get($colors, primary);
  font-weight: 400;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border .15s ease-in,
              color .15s ease-in,
              background-color .15s ease-in;

  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    color: map-get($colors, black);
    border-bottom: 1px solid map-get($colors, black);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: map-get($fonts, header);
  font-size: 116%;
  font-weight: bold;
  letter-spacing: -0.025em;
  margin: 0;
  padding :0;

  a {
    line-height: 150%;
  }
}

strong {
  font-weight: 700;
}

#doc3 {
  margin: 0;
}

#bd {
  margin: $grid-gutter-width 0;

  .main-content {
    padding: 1rem;
    background: #fff;
  }
}

.clear {
  clear: both;
}

.tag {
  display: inline-block;
  padding: .2em;
  font-weight: 400;
  font-size: .9em;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  background-color: map-get($colors, primary);

  &.new {
    background-color: map-get($colors, yellow-200);
  }
}

ol {
  margin: 1rem;

  li {
    list-style-type: decimal;
  }
}

#trc-show-hide-sidebar {
  display: none;
}

// @todo not sure where or if this is displayed, move to proper scss file
.experience_level {
	border-radius: .5em;
	color: map-get($colors, black);
	display: inline-block;
	height: 1.6em;
	line-height: 1.6em;
	margin-left: 1em;
	padding: 0 .5em;
}

.experience_level.rookie {
	background-color: #1b9c2d;
}
