#main-nav {
  margin: 1rem -#{$grid-gutter-width} 0 -#{$grid-gutter-width};
  overflow: hidden;
  border-bottom: 5px solid map-get($colors, primary);

  li a {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 115%;
    text-transform: uppercase;
  }
}
