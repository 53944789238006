.swat-pagination {
  background-color: map-get($colors, gray-100);

  div.swat-pagination-position {
    margin: 0 0 .25rem 0;
    font-weight: 300;
    color: map-get($colors, gray-700);
  }

  a,
  span,
  span.swat-pagination-nextprev,
  span.swat-pagination-current {
    font-size: 108%;
    border: 0;
    border-bottom: 1px solid transparent;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      background: none;
      border-bottom-color: map-get($colors, black);
    }
  }

  span.swat-pagination-current {
    border-bottom-color: map-get($colors, black);
  }
}
